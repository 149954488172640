import { Stack, Skeleton, Text } from '@backyard-ui/core';
import { uniqueId } from 'lodash';

import { Star } from './components/Star';
import { getDecimalsFromRating } from './utils';

export interface RatingStarsProps {
  rating: number;
  showRating?: boolean;
  totalReviewsCount?: number;
  isFetchingRatings?: boolean;
  showTotalReviewsCount?: boolean;
}

const STARS = new Array(5).fill({ key: uniqueId() });

function RatingStars(props: RatingStarsProps) {
  const {
    rating,
    isFetchingRatings,
    showRating,
    showTotalReviewsCount,
    totalReviewsCount,
  } = props;

  if (isFetchingRatings) {
    return (
      <Skeleton width="96px" height="18px" data-testid="rating-skeleton" />
    );
  }

  return (
    <Stack
      spacing="1.5"
      data-testid="rating-stars"
      align="center"
      justify="center"
    >
      <Stack
        spacing="0.5"
        aria-label={`Avaliação: ${rating} de ${STARS.length} estrelas`}
        align="center"
        justify="center"
      >
        {STARS.map(({ key }, index) => {
          const decimals = getDecimalsFromRating({
            rating,
            index,
          });

          return <Star key={key} decimals={decimals} />;
        })}
      </Stack>
      <Stack spacing="0.5" align="center" justify="center">
        {showRating && (
          <Text
            data-testid="rating"
            color="gray-600"
            size="md"
            weight="bold"
            asChild
          >
            <span>{`${rating}`}</span>
          </Text>
        )}
        {showTotalReviewsCount && (
          <Text
            data-testid="total-reviews-count"
            color="gray-600"
            size="md"
            weight="bold"
            asChild
          >
            <span>{`(${totalReviewsCount})`}</span>
          </Text>
        )}
      </Stack>
    </Stack>
  );
}

export default RatingStars;
