import { useId } from 'react';

import { colors } from '@backyard-ui/styles';

import { styles } from './Star.styles';

interface StarProps {
  decimals?: number;
}

function Star(props: StarProps) {
  const { decimals } = props;

  const id = useId();
  const classNames = styles();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 13 13"
      aria-hidden="true"
      className={classNames}
      data-testid="star"
    >
      <defs>
        <linearGradient
          id={id}
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
          data-testid="star-decimals"
        >
          <stop
            offset={decimals}
            stopColor="currentColor"
            data-testid="star-stop"
          />
          <stop
            offset={decimals}
            stopColor={colors['gray-200']}
            data-testid="star-stop"
          />
        </linearGradient>
      </defs>
      <path
        data-testid="star-path"
        fill={`url(#${id})`}
        d="M3.813 12.135a1.29 1.29 0 0 1-.7-.22 1.22 1.22 0 0 1-.45-1.38l1-2.94-2.36-1.33a1.16 1.16 0 0 1-.56-1.35 1.19 1.19 0 0 1 1.17-.86h2.74l.87-3a1.27 1.27 0 0 1 1.21-.92 1.22 1.22 0 0 1 1.23.91l.89 2.93h2.65a1.19 1.19 0 0 1 1.115 1.617 1.19 1.19 0 0 1-.485.583l-2.17 1.35 1 2.81a1.3 1.3 0 0 1-.48 1.48 1.29 1.29 0 0 1-1.55 0l-2.19-1.66-2.13 1.68a1.19 1.19 0 0 1-.8.3Z"
      />
    </svg>
  );
}

export default Star;
